<template>
  <customer-support></customer-support>
</template>

<script>
import CustomerSupport from '../admin/CustomerSupport.vue'
export default {
  components: {
    CustomerSupport
  }
}
</script>